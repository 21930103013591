import React from 'react';

function Footer() {
  return (
    <footer className="footer">
    <p>© 2024 Alex Mahadevan. All rights reserved. Email me: alex@poynter.org.</p>
  </footer>
  );
}

export default Footer;